import styled, { css } from "styled-components";
import { mediaMax } from "helpers/mediaQueries";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import Link from "components/cms/link";
import { Link as GatsbyLink } from "gatsby";
import LogoIcon from "icons/footer-logo.svg";
import DboxIcon from "icons/dbox-footer-logo.svg";
// import DboxIcon from "icons/dbox-logo.png";
import NfIcon from "icons/nf-footer-logo.svg";
import BineralsIcon from "icons/binerals-footer-logo.svg";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${calculateResponsiveSize(120)} ${calculateResponsiveSize(20)}
    ${calculateResponsiveSize(54)} ${calculateResponsiveSize(20)};
  background-color: #000;
  ${mediaMax("tabletLandscape")} {
    padding: 50px 15px 50px 15px;
  }
`;

export const Logo = styled(GatsbyLink)`
  width: ${calculateResponsiveSize(97)};
  height: ${calculateResponsiveSize(74)};
  background-image: url(${LogoIcon});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Rows = styled.div`
  width: 100%;
`;

export const FooterColumnMenu = styled.div`
  display: flex;
  width: 23%;
  flex-direction: column;
  &:first-child,
  &:nth-child(5) {
    width: 28%;
  }

  /* &:nth-child(3),
  :nth-child(6) {
    width: 23%;
  } */
  &:nth-child(3) {
    width: 21%;
  }
  &:nth-child(2) {
    width: 24%;
  }

  &.non-clickable {
    pointer-events: none;
  }
  &:last-child {
    margin-right: 0;
  }
  ${mediaMax("tabletLandscape")} {
    max-width: 100%;
    width: 30% !important;
  }
  ${mediaMax("tablet")} {
    width: 100% !important;
    margin-right: 0;
  }
`;

export const InnerRow = styled.div`
  display: flex;
  border-bottom: solid ${calculateResponsiveSize(1)} #aba6a6;
  padding: ${calculateResponsiveSize(55)} 0;
  &.icons {
    align-items: center;
  }
  &.design {
    padding-top: ${calculateResponsiveSize(20)};
    align-items: center;
    justify-content: space-between;
  }

  &.bottom-inner-row {
    flex-wrap: wrap;
  }
  &.menu-items {
    ${FooterColumnMenu} {
      &:first-child {
        width: 28%;
      }
      &:nth-child(2) {
        width: 23%;
      }
      &:nth-child(3) {
        width: 22%;
      }
    }
  }
  ${mediaMax("tablet")} {
    padding-top: 16px;
    flex-direction: column;
    align-items: center;
    &.icons {
      padding-left: 20px;
      align-items: flex-start;
    }
    &.design {
      padding-top: 0;
      align-items: flex-start;
    }
    &.menu-items {
      padding: 50px 0 35px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      border-top: solid 1px #aba6a6;
      ${FooterColumnMenu} {
        width: 50% !important;
        &:last-child {
          position: absolute;
          left: 50%;
        }
      }
    }
    &.bottom-inner-row {
      align-items: flex-start;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      ${FooterColumnMenu} {
        &:last-child {
          flex-direction: row;
        }
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${calculateResponsiveSize(490)};
  margin-bottom: ${calculateResponsiveSize(32)};
  &:last-of-type {
    border-bottom: 0;
    ${InnerRow} {
      border-bottom: 0;
    }
  }
  &.bottom-row {
    margin-bottom: 0;
  }
  ${mediaMax("tabletLandscape")} {
    padding-left: 0;
  }
  ${mediaMax("tablet")} {
    margin-bottom: 20px;
  }
`;

export const TextStyles = css`
  font-size: ${calculateResponsiveSize(16)};
  line-height: 1.68;
  color: #fff;
  ${mediaMax("tabletLandscape")} {
    font-size: 16px;
    margin-bottom: 20px;
  }
  ${mediaMax("tablet")} {
    font-size: 15px;
    /* &:last-child {
      margin-bottom: 0;
    } */
  }
`;

export const Title = styled.p`
  ${TextStyles}
  &.design {
    margin-bottom: ${calculateResponsiveSize(44)};
  }
  &.credits {
    margin-bottom: ${calculateResponsiveSize(50)};
  }
  &.bottom-inner-title {
    margin-bottom: ${calculateResponsiveSize(45)};
  }

  ${mediaMax("tablet")} {
    &,
    &.bottom-inner-title,
    &.credits {
      margin-bottom: 40px;
    }
    &.bottom-title {
      margin-bottom: 10px;
    }
  }
`;

export const FooterColumn = styled.div`
  display: flex;
  /* min-width: ${calculateResponsiveSize(200)}; */
  flex-direction: column;
  &.first-row {
    margin-right: ${calculateResponsiveSize(200)};

    &.column-1 {
      img {
        width: ${calculateResponsiveSize(60)};
      }
    }
    &.column-2 {
      img {
        width: ${calculateResponsiveSize(70)};
      }
    }
    &.column-3 {
      img {
        width: ${calculateResponsiveSize(30)};
      }
    }
  }
  &.second-row {
    margin-right: ${calculateResponsiveSize(88)};
    &.column-1 {
      img {
        width: ${calculateResponsiveSize(190)};
      }
    }
    &.column-2 {
      img {
        width: ${calculateResponsiveSize(160)};
      }
    }
    &.column-3 {
      img {
        width: ${calculateResponsiveSize(230)};
      }
    }
  }
  &.third-row {
    &.column-1 {
      img {
        width: ${calculateResponsiveSize(100)};
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
  &.design {
    justify-content: center;
  }
  ${mediaMax("tabletLandscape")} {
    &.first-row {
      &.column-1 {
        img {
          width: 60px;
        }
      }
      &.column-2 {
        img {
          width: 70px;
        }
      }
      &.column-3 {
        img {
          width: 30px;
        }
      }
    }
    &.third-row {
      &.column-1 {
        img {
          width: 100px;
        }
      }
    }
    &.second-row {
      margin-right: 88px;
      &.column-1 {
        img {
          width: 190px;
        }
      }
      &.column-2 {
        img {
          width: 160px;
        }
      }
      &.column-3 {
        img {
          width: 230px;
        }
      }
    }
  }
  ${mediaMax("tablet")} {
    width: auto;
    margin-right: 0 !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &.design {
      &:last-of-type a {
        margin-bottom: 16px;
      }
    }
  }
`;

export const NavLink = styled(Link)`
  ${TextStyles}
  font-weight: 400;
  text-decoration: none;
  &:last-child {
    margin-right: 0;
  }
  ${mediaMax("tablet")} {
    line-height: 1.5;
  }
`;

export const IconItem = styled.div`
  position: relative;
  a {
    cursor: pointer;
    font-size: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  ${mediaMax("tabletLandscape")} {
    margin-bottom: 32px;
  }
`;

export const DesignIcon = styled.a`
  text-decoration: none;
  font-size: 0;

  &.dbox {
    /* margin-right: ${calculateResponsiveSize(112)}; */
    background-image: url(${DboxIcon});
    background-repeat: no-repeat;
    background-size: contain;
    width: ${calculateResponsiveSize(69)};
    height: ${calculateResponsiveSize(16)};
  }
  &.nf {
    background-image: url(${NfIcon});
    background-repeat: no-repeat;
    background-size: contain;
    width: ${calculateResponsiveSize(264)};
    height: ${calculateResponsiveSize(40)};
  }
  &.binerals {
    /* margin-right: ${calculateResponsiveSize(168)}; */
    background-image: url(${BineralsIcon});
    background-repeat: no-repeat;
    background-size: contain;
    width: ${calculateResponsiveSize(106)};
    height: ${calculateResponsiveSize(22)};
  }

  ${mediaMax("tabletLandscape")} {
    margin-right: 0;
    margin-bottom: 40px;
    &.binerals {
      margin-left: 20px;
      width: 106px;
      height: 22px;
    }
    &.nf {
      width: 264px;
      height: 40px;
    }
    &.dbox {
      margin-left: 20px;
      width: 69px;
      height: 16px;
    }
  }
  ${mediaMax("tablet")} {
    /* margin-bottom: 32px; */
    margin-bottom: 24px;
    &.binerals {
      margin-left: 0;
      margin-top: 10px;
    }
    &.nf {
      margin-left: -15px;
    }
    &.dbox {
      margin-left: 0;
    }
  }
`;

export const Copyright = styled.p`
  padding-left: ${calculateResponsiveSize(490)};
  padding-top: ${calculateResponsiveSize(50)};
  color: #949393;
  font-size: ${calculateResponsiveSize(15)};
  line-height: 1.8;
  text-align: right;
  ${mediaMax("tabletLandscape")} {
    font-size: 15px;
    padding-left: 0;
    text-align: left;
  }
`;

export const TopFooterRow = styled.div`
  padding-left: ${calculateResponsiveSize(490)};
  border-bottom: solid ${calculateResponsiveSize(1)} #aba6a6;
  ${Row} {
    padding-left: 0;
    &:first-child {
      padding-top: ${calculateResponsiveSize(32)};
      border-top: solid ${calculateResponsiveSize(1)} #aba6a6;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${mediaMax("tabletLandscape")} {
    padding-left: 0;
    ${Row} {
      &:first-child {
        padding-top: 32px;
      }
    }
  }
  ${mediaMax("tablet")} {
    display: none;
  }
`;
