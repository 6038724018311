import React, { useContext } from "react";
import { Wrapper, NavLink, Nav } from "./index.styled";
import { LanguageContext } from "context/locale-context";
import { useStaticQuery, graphql } from "gatsby";

const createBreadcrumbs = (location, pages, language) => {
  const path = location.replace(/^\/|\/$/g, "");

  const segments = path.split("/");

  const breadcrumbs = segments.map((segment, index) => {
    if (segment === "index") {
      segment = "home";
    }

    let label = segment
      .replace(/-/g, " ")
      .replace(/^\w/, (c) => c.toUpperCase());

    let path = "/" + segments.slice(0, index + 1).join("/") + "/";
    path = path.replace(/\/$/g, "");

    const page = pages.find((page) => {
      const isMatch = `/${page.Slug}` === path && page.locale === language;

      return isMatch;
    });

    if (page) {
      label = page.Name || page.Title;
    }

    return { label, path };
  });

  return breadcrumbs;
};

const Breadcrumbs = ({ location, textColor, slug }) => {
  const { language } = useContext(LanguageContext);

  // Query the pages
  const data = useStaticQuery(graphql`
    query {
      allStrapiPage {
        edges {
          node {
            Name
            locale
            Slug
          }
        }
      }
      allStrapiNew {
        edges {
          node {
            Slug
            Title
            locale
          }
        }
      }
      allStrapiConnect {
        edges {
          node {
            Slug
            Title
            locale
          }
        }
      }
    }
  `);

  let pages;
  const { pathname } = location;
  if (/\/connect(\/.+)?$/.test(pathname)) {
    pages = data.allStrapiConnect.edges.map((edge) => edge.node);
  } else if (/\/news(\/.+)?$/.test(pathname)) {
    pages = data.allStrapiNew.edges.map((edge) => edge.node);
  } else {
    pages = data.allStrapiPage.edges.map((edge) => edge.node);
  }

  const crumbs = slug ? createBreadcrumbs(slug, pages, language) : [];

  return (
    <Wrapper $textColor={textColor}>
      <Nav>
        <NavLink to="/" $textColor={textColor}>
          {language === "en" ? "home" : "Головна"}
        </NavLink>
        {crumbs.map((crumb, index) => (
          <NavLink
            key={`breadcrumbs-link-${index}`}
            to={crumb.path}
            $textColor={textColor}
          >
            {crumb.label}
          </NavLink>
        ))}
      </Nav>
    </Wrapper>
  );
};

export default Breadcrumbs;
