import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    /* :root {
        
    } */
    li {
        list-style: none;
    }
    * {
      margin: 0;
      padding: 0;
      font-family: inherit;
      box-sizing: border-box;
      font-family: 'NeueHaasGroteskTextPro', sans-serif;
      font-weight: 400;
    }

    html, body {
      height: 100%;
      background-color: #000;
    }

    body {
      width: 100%;
      position: relative;
 
    }
 /* .smooth-content, 
 .section-inner {
    will-change: transform;
  } */
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }
`;

export default GlobalStyle;
