import styled from "styled-components";
import { mediaMax } from "helpers/mediaQueries";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import Link from "components/cms/link";
import { Link as GatsbyLink } from "gatsby";
import IconDown from "icons/arrow.svg";
import IconDownWhite from "icons/arrow-white.svg";
import SearchIcon from "icons/search-icon.svg";
import SearchIconWhite from "icons/search-icon-white.svg";
import MenuIconWhite from "icons/menu-icon-white.svg";
import CloseIcon from "icons/close-icon.svg";
import MenuIcon from "icons/menu-icon-black.svg";

export const Wrapper = styled.div`
  position: fixed;

  top: ${calculateResponsiveSize(-110)};
  left: 0;
  width: 100%;
  padding: ${calculateResponsiveSize(24)} ${calculateResponsiveSize(65)};
  z-index: 111;
  transition: top 0.3s;
  &.visible {
    top: 0;
  }
  ${mediaMax("tabletLandscape")} {
    padding: 0;
  }
  ${mediaMax("tablet")} {
    top: -110px;
  }
`;
export const DesktopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mediaMax("tabletLandscape")} {
    display: none;
  }
`;

export const Logo = styled(GatsbyLink)`
  display: grid;
  width: ${({ $langauage }) =>
    $langauage === "en"
      ? `${calculateResponsiveSize(125)}`
      : ` ${calculateResponsiveSize(145)};`};

  /* ${mediaMax("tabletLandscape")} {
    width: 69px;
    height: 90px;
  } */
  ${mediaMax("tabletLandscape")} {
    height: auto;
    width: 125px;
  }
`;

export const LogoText = styled.div`
  grid-area: 1/1;
`;
export const Icon = styled.div`
  grid-area: 1/1;
  position: relative;
  z-index: 1;
`;

export const Nav = styled.nav`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: "Cartograph-cf";
  margin-right: ${calculateResponsiveSize(50)};
  color: inherit;
  text-transform: uppercase;
  font-size: ${calculateResponsiveSize(13)};
  letter-spacing: ${calculateResponsiveSize(0.26)};
  text-decoration: none;
  &:last-child {
    margin-right: 0;
  }
  &:after {
    content: "";
    display: ${({ $arrow }) => ($arrow ? "block" : "none")};
    margin-left: ${calculateResponsiveSize(12)};
    background-image: ${({ $headerTextColor }) =>
      $headerTextColor === "White"
        ? `url(${IconDownWhite})`
        : `url(${IconDown})`};
    background-repeat: no-repeat;
    width: ${calculateResponsiveSize(7)};
    height: ${calculateResponsiveSize(5)};
    background-size: contain;
    flex-shrink: 0;
    ${({ $isHeaderVisible }) =>
      $isHeaderVisible
        ? `
      background-image:url(${IconDown});
    `
        : ""}
  }

  ${mediaMax("tabletLandscape")} {
    margin-right: 0;
    font-size: 19px;
    letter-spacing: 1.4px;
    margin-bottom: 0;
  }
  /* {
   margin-bottom: ${({ $arrow }) => ($arrow ? "0" : "none")};
  } */
`;

export const SubNavWrapper = styled.div`
  position: absolute;
  left: ${calculateResponsiveSize(-10)};
  top: ${calculateResponsiveSize(28)};
  overflow: hidden;
  padding: 0 ${calculateResponsiveSize(10)};
`;
export const SubNav = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  ${NavLink} {
    margin-bottom: ${calculateResponsiveSize(9)};
  }

  &:before {
    content: "";
    position: absolute;
    display: ${({ $display }) => ($display ? "block" : "none")};
    height: 100%;
    width: 120%;
    left: -10%;
    background-color: ${({ $headerTextColor }) =>
      $headerTextColor === "White" ? "rgba(0,0,0, 0.5)" : "#EDEAE7"};
    z-index: -1;
    opacity: 0.5;
  }
  ${mediaMax("tabletLandscape")} {
    position: relative;
    padding-left: 30px;
    max-height: fit-content;
    top: 8px;
    ${NavLink} {
      margin-bottom: 10px;
    }
  }
`;
export const NavLinkWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  color: ${({ $headerTextColor }) =>
    $headerTextColor === "White" ? "#fff" : "#000"};
  /* overflow: hidden; */
  &:hover {
    ${SubNav} {
      max-height: 500px;
      transition: all 0.5s ease-in;
    }
  }
  &:last-child {
    display: none;
  }
  ${mediaMax("tabletLandscape")} {
    margin-bottom: 20px;
    color: #000;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  padding-right: ${calculateResponsiveSize(96)};
`;

export const SearchButton = styled.button`
  display: block;
  position: relative;
  outline: none;
  border: 0;
  background-color: transparent;
  width: ${calculateResponsiveSize(15)};
  height: ${calculateResponsiveSize(18)};
  background-image: ${({ $headerTextColor }) =>
    $headerTextColor === "White"
      ? `url(${SearchIconWhite})`
      : `url(${SearchIcon})`};
  background-repeat: no-repeat;
  background-size: contain;
  /* ${({ $isHeaderVisible }) =>
    $isHeaderVisible
      ? `
      background-image:url(${SearchIcon});
    `
      : ""} */
  cursor: pointer;
  ${mediaMax("tabletLandscape")} {
    width: 18px;
    height: 22px;
    margin-right: 30px;
    ${({ $isOpenMobileMenu }) =>
      $isOpenMobileMenu ? `background-image:url(${SearchIcon})` : ` `};
  }
`;

export const SwitchLanguageButtonInner = styled.div`
  height: ${calculateResponsiveSize(14)};
  overflow: hidden;
  ${mediaMax("tabletLandscape")} {
    height: 14px;
  }
`;

export const SwitchLanguageButton = styled.div`
  width: ${calculateResponsiveSize(52)};
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: ${({ $headerTextColor }) =>
      $headerTextColor === "White" ? "#fff" : "#000"};

    clip-path: polygon(
      0 ${calculateResponsiveSize(4)},
      ${calculateResponsiveSize(4)} 0,
      100% 0,
      100% calc(100% - ${calculateResponsiveSize(4)}),
      calc(100% - ${calculateResponsiveSize(4)}) 100%,
      0 100%,
      0 ${calculateResponsiveSize(4)},
      ${calculateResponsiveSize(1)}
        calc(${calculateResponsiveSize(4)} + ${calculateResponsiveSize(0.41)}),
      ${calculateResponsiveSize(1)} calc(100% - ${calculateResponsiveSize(1)}),
      calc(
          100% - ${calculateResponsiveSize(4)} -
            ${calculateResponsiveSize(0.41)}
        )
        calc(100% - ${calculateResponsiveSize(1)}),
      calc(100% - ${calculateResponsiveSize(1)})
        calc(
          100% - ${calculateResponsiveSize(4)} -
            ${calculateResponsiveSize(0.41)}
        ),
      calc(100% - ${calculateResponsiveSize(1)}) ${calculateResponsiveSize(1)},
      calc(${calculateResponsiveSize(4)} + ${calculateResponsiveSize(0.41)})
        ${calculateResponsiveSize(1)},
      ${calculateResponsiveSize(1)}
        calc(${calculateResponsiveSize(4)} + ${calculateResponsiveSize(0.41)})
    );
  }
  position: absolute;
  right: ${calculateResponsiveSize(24)};
  top: ${calculateResponsiveSize(34)};
  padding: ${calculateResponsiveSize(5)} ${calculateResponsiveSize(18)}
    ${calculateResponsiveSize(14)} ${calculateResponsiveSize(12)};

  background: transparent;
  overflow: hidden;
  height: ${calculateResponsiveSize(24)};
  transition: height 0.5s ease;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    top: ${calculateResponsiveSize(8)};
    right: ${calculateResponsiveSize(10)};
    background-image: ${({ $headerTextColor }) =>
      $headerTextColor === "White"
        ? `url(${IconDownWhite})`
        : `url(${IconDown})`};
    /* ${({ $isHeaderVisible }) =>
      $isHeaderVisible
        ? `
        background-image:url(${IconDown});
    `
        : ""} */
    background-repeat: no-repeat;
    width: ${calculateResponsiveSize(7)};
    height: ${calculateResponsiveSize(5)};
    background-size: contain;
  }
  &.active {
    transition: height 0.5s ease;
    height: ${calculateResponsiveSize(38)};
    /* border-radius: ${calculateResponsiveSize(12)}; */
    &:after {
      transform: rotate(180deg);
    }
    ${SwitchLanguageButtonInner} {
      height: auto;
    }
  }
  ${mediaMax("tabletLandscape")} {
    width: 52px;
    height: 24px;
    position: relative;
    top: auto;
    right: auto;
    margin-top: -2px;
    margin-right: 30px;
    padding: 5px 14px;
    &:before {
      content: "";
      clip-path: polygon(
        0 4px,
        4px 0,
        100% 0,
        100% calc(100% - 4px),
        calc(100% - 4px) 100%,
        0 100%,
        0 4px,
        1px calc(4px + 0.4px),
        1px calc(100% - 1px),
        calc(100% - 4px - 0.4px) calc(100% - 1px),
        calc(100% - 1px) calc(100% - 4px - 0.4px),
        calc(100% - 1px) 1px,
        calc(4px + 0.4px) 1px,
        1px calc(4px + 0.4px)
      );
    }
    &.active {
      height: 38px;
    }
    &:after {
      width: 7px;
      height: 5px;
      top: 8px;
      right: 10px;
    }
  }
  ${mediaMax("tablet")} {
    &:after {
      background-image: url(${IconDown});
    }
    &:before {
      background: #000;
    }
  }
`;

export const LanguageButton = styled.button`
  display: block;
  font-size: ${calculateResponsiveSize(12)};
  text-transform: uppercase;
  letter-spacing: ${calculateResponsiveSize(1.2)};
  line-height: 1;
  margin-bottom: ${calculateResponsiveSize(2)};
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  font-family: "Cartograph-cf";
  color: ${({ $headerTextColor }) =>
    $headerTextColor === "White" ? "#fff" : "#000"};
  /* ${({ $isHeaderVisible }) =>
    $isHeaderVisible
      ? `
        color:#000;
    `
      : ""} */
  ${mediaMax("tabletLandscape")} {
    font-size: 12px;
    margin-bottom: 2px;
    letter-spacing: 1.2px;
  }
  ${mediaMax("tablet")} {
    color: #000;
  }
`;

export const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
`;
export const MobileContent = styled.div`
  padding-top: 65px;
  ${({ $show }) =>
    !$show &&
    `display:none;
  `};
`;
export const MobileWrapper = styled.div`
  display: none;

  padding: 30px 16px 16px;
  top: 0;
  left: 0;
  overflow: hidden;

  ${({ $openSearch }) =>
    $openSearch &&
    `height: 150px;

  `};
  ${({ $show }) =>
    $show &&
    `height: 100vh;
     background-color: #EDEAE7;
  `};

  width: 100%;
  ${mediaMax("tabletLandscape")} {
    display: block;
  }
`;
export const NavMobile = styled.div``;

export const MenuIconButton = styled.button`
  border: 0;
  background-color: transparent;
  background-image: ${({ $headerTextColor }) =>
    $headerTextColor === "White"
      ? `url(${MenuIconWhite})`
      : `url(${MenuIcon})`};

  width: 22px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  &.active {
    background-image: url(${CloseIcon});
    width: 19px;
    height: 19px;
  }
`;
export const MobileHeaderButtons = styled.div`
  display: flex;
  padding-top: 8px;
`;

export const SearchWrapper = styled.div`
  position: absolute;
  top: ${calculateResponsiveSize(140)};
  right: ${calculateResponsiveSize(124)};
  /* width: ${calculateResponsiveSize(220)}; */
  display: flex;
  padding: ${calculateResponsiveSize(4)} 0;
  border-bottom: ${calculateResponsiveSize(1)} solid
    ${({ $headerTextColor }) =>
      $headerTextColor === "White" ? "#fff" : "#000"};
  align-items: center;
  justify-content: space-between;
  button,
  input {
    color: ${({ $headerTextColor }) =>
      $headerTextColor === "White" ? "#fff" : "#000"};
  }
  ${mediaMax("tabletLandscape")} {
    top: 100px;
    right: 56px;
  }
`;

export const SearchInput = styled.input`
  font-family: "Cartograph-cf";
  font-size: ${calculateResponsiveSize(13)};
  width: ${calculateResponsiveSize(160)};
  letter-spacing: ${calculateResponsiveSize(0.26)};
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  outline: none;
  ${mediaMax("tabletLandscape")} {
    width: 180px;
    font-size: 16px;
  }
`;

export const SearchInputButton = styled.button`
  font-family: "Cartograph-cf";
  font-size: ${calculateResponsiveSize(13)};
  letter-spacing: ${calculateResponsiveSize(0.26)};
  line-height: 1;
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  ${mediaMax("tabletLandscape")} {
    font-size: 14px;
  }
`;
