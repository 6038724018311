import React, { useContext } from "react";
import {
  Wrapper,
  Title,
  FooterColumn,
  NavLink,
  Row,
  DesignIcon,
  Rows,
  InnerRow,
  IconItem,
  FooterColumnMenu,
  Copyright,
  TopFooterRow,
} from "./index.styled";
import { useStaticQuery, graphql } from "gatsby";
import { LanguageContext } from "context/locale-context";

const Footer = ({ location }) => {
  const { language } = useContext(LanguageContext);
  const { allStrapiFooterMenu } = useStaticQuery(graphql`
    query FooterQuery {
      allStrapiFooterMenu {
        edges {
          node {
            locale
            FooterHome {
              FooterRowTitle
              FooterColumnWithLinks {
                className
                Icon {
                  localFile {
                    publicURL
                  }
                }
                Link {
                  Title
                  ExternalUrl
                  File {
                    localFile {
                      publicURL
                    }
                  }
                  page {
                    Slug
                  }
                }
              }
            }
            FooterBottomMenu {
              ClassName
              Link {
                Title
                ExternalUrl
                File {
                  localFile {
                    publicURL
                  }
                }
                page {
                  Slug
                }
              }
            }
            CopyrightText
            FooterBottomText
            FooterMenu {
              Link {
                Title
                ExternalUrl
                File {
                  localFile {
                    publicURL
                  }
                }
                page {
                  Slug
                }
              }
            }
          }
        }
      }
    }
  `);
  const footerMenu = allStrapiFooterMenu.edges.find(
    (node) => node.node.locale === language
  );

  if (!footerMenu) {
    return null; // or return some default JSX
  }

  const {
    node: {
      FooterHome,
      FooterBottomMenu,
      CopyrightText,
      FooterMenu,
      FooterBottomText,
    },
  } = footerMenu;

  const designItems = [
    { link: "https://binerals.com/", icon: "binerals" },
    { link: "https://dbox.com/", icon: "dbox" },
    { link: "https://normanfosterfoundation.org/", icon: "nf" },
  ];

  return (
    <Wrapper>
      {/* <Logo to={"/"} /> */}
      <Rows>
        {location.pathname === "/" && "home" && (
          <TopFooterRow>
            {FooterHome.map((row, i) => {
              return (
                <Row key={`footer-top-row-${i}`}>
                  <Title>{row.FooterRowTitle}</Title>
                  <InnerRow className="icons">
                    {row.FooterColumnWithLinks?.map((column, i) => {
                      return (
                        <FooterColumn
                          key={`footer-top-row-column-${i}`}
                          className={column.className}
                        >
                          <IconItem>
                            {column.Link && <NavLink data={column.Link} />}
                            <img
                              src={column.Icon?.localFile.publicURL}
                              alt=""
                            />
                          </IconItem>
                        </FooterColumn>
                      );
                    })}
                  </InnerRow>
                </Row>
              );
            })}
          </TopFooterRow>
        )}
        <Row>
          <InnerRow className="menu-items">
            {FooterMenu.map((menuColumn, i) => {
              return (
                <FooterColumnMenu key={`footer-menu-column-info-${i}`}>
                  {menuColumn.Link.map((item, j) => {
                    return (
                      <NavLink
                        data={item}
                        key={`footer-menu-column-info-link-${i}-${j}`}
                      />
                    );
                  })}
                </FooterColumnMenu>
              );
            })}
          </InnerRow>
        </Row>
        <Row>
          <InnerRow className="design">
            <Title>Design & Production</Title>
            {designItems.map((designItem, i) => {
              return (
                <FooterColumn
                  key={`footer-top-row-column-${i}`}
                  className={"design"}
                >
                  <DesignIcon
                    href={designItem.link}
                    target="_blank"
                    className={designItem.icon}
                  />
                </FooterColumn>
              );
            })}
          </InnerRow>
        </Row>
        <Row className="bottom-row">
          <Title className="credits">
            {language === "en" ? "Credits" : "Кредити"}
          </Title>
          <Title className="bottom-inner-title">{FooterBottomText}</Title>

          <InnerRow className="bottom-inner-row">
            {FooterBottomMenu?.map((bottomColumn, i) => {
              console.log(bottomColumn);
              return (
                <FooterColumnMenu
                  key={`footer-bottom-column-info-${i}`}
                  className={bottomColumn.ClassName}
                >
                  {bottomColumn.Link.map((item, j) => {
                    return (
                      <NavLink
                        data={item}
                        key={`footer-bottom-menu-column-info-link-${i}-${j}`}
                      />
                    );
                  })}
                </FooterColumnMenu>
              );
            })}
          </InnerRow>
        </Row>
        <Copyright>{CopyrightText}</Copyright>
      </Rows>
    </Wrapper>
  );
};

export default Footer;
