import styled from "styled-components";
import { mediaMax } from "helpers/mediaQueries";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { Link } from "gatsby";
import IconArrow from "icons/arrow.svg";
import IconArrowWhite from "icons/arrow-white.svg";

export const Wrapper = styled.div`
  position: absolute;
  top: ${calculateResponsiveSize(105)};
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 ${calculateResponsiveSize(70)};
  z-index: 99;
  color: ${({ $textColor }) => ($textColor === "White" ? "#fff" : "#010101")};
  ${mediaMax("tabletLandscape")} {
    display: none;
  }
`;

export const Nav = styled.nav`
  display: flex;
`;

export const NavLink = styled(Link)`
  display: flex;
  position: relative;
  align-items: center;
  padding-right: ${calculateResponsiveSize(20)};
  color: inherit;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 1;
  font-size: ${calculateResponsiveSize(12)};
  letter-spacing: ${calculateResponsiveSize(0.8)};
  text-decoration: none;
  &:last-child {
    pointer-events: none;
    text-decoration: underline;
    text-underline-offset: ${calculateResponsiveSize(3)};
    &:after {
      display: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: ${calculateResponsiveSize(3)};
    right: ${calculateResponsiveSize(7)};
    background-image: ${({ $textColor }) =>
      $textColor === "White" ? `url(${IconArrowWhite})` : `url(${IconArrow})`};
    background-repeat: no-repeat;
    width: ${calculateResponsiveSize(9)};
    height: ${calculateResponsiveSize(4.5)};
    background-size: contain;
    transform: rotate(-90deg);
  }
`;
