import * as React from "react";
import { InternalLink, ExternalLink } from "./index.styled";

const Link = ({ data, className, onClick }) => {
  if (
    data &&
    (data.page || (data.ExternalUrl && data.ExternalUrl[0] === "/"))
  ) {
    const pageLink =
      data.page?.Slug === "index"
        ? "/"
        : `/${data.page?.Slug || data.ExternalUrl}`;
    return (
      <InternalLink onClick={onClick} to={pageLink} className={className}>
        {data.Title}
      </InternalLink>
    );
  } else if (data && data.File) {
    return (
      <ExternalLink
        target="_blank"
        href={data.File?.localFile?.publicURL}
        className={className}
      >
        {data.Title}
      </ExternalLink>
    );
  } else if (data && data.ExternalUrl) {
    return (
      <ExternalLink
        target="_blank"
        href={data.ExternalUrl}
        className={className}
      >
        {data.Title}
      </ExternalLink>
    );
  } else {
    return null;
  }
};

export default Link;
