import React from "react";
import { LanguageProvider } from "./src/context/locale-context";
import { SearchProvider } from "./src/context/search-context";

export const wrapRootElement = ({ element }) => {
  return (
    <LanguageProvider>
      <SearchProvider>{element}</SearchProvider>
    </LanguageProvider>
  );
};
