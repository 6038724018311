import React, { useContext, useEffect } from "react";
import { Transition, TransitionGroup } from "react-transition-group";
//import PropTypes from "prop-types";
import GlobalStyle from "styles/globalStyles";
import Header from "components/header";
import Footer from "components/footer";
import { LayoutWrapper, PageWrapper, ContentWrapper } from "./index.styled";
import { LanguageContext } from "context/locale-context";
import "styles/fonts.css";
import Breadcrumbs from "components/breadcrumbs";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CookiesComponent from "../cookies";

gsap.registerPlugin(ScrollTrigger);

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 0 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <LayoutWrapper id="layoutWrapper">{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  );
};

const LayoutContainer = ({ children, location, pageContext }) => {
  return (
    <LayoutInner
      children={children}
      location={location}
      pageContext={pageContext}
    />
  );
};

const LayoutInner = ({ children, location, pageContext }) => {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [location]);

  return (
    <>
      <Header
        location={location}
        headerTextColor={pageContext[language]?.HeaderTextColor}
        className={location.pathname === "/" && "home"}
      />
      {location.pathname !== "/" && (
        <Breadcrumbs
          location={location}
          textColor={pageContext[language]?.HeaderTextColor}
          // name={pageContext[language].Name}
          slug={pageContext[language]?.Slug}
        />
      )}

      <PageWrapper>
        <GlobalStyle />
        <CookiesComponent />
        <ContentWrapper>
          <LayoutContent location={location}>{children}</LayoutContent>
        </ContentWrapper>
      </PageWrapper>
      <Footer location={location} />
    </>
  );
};

// LayoutContainer.propTypes = {
//   children: PropTypes.node.isRequired,
//   location: PropTypes.object.isRequired,
//   pageContext: PropTypes.object.isRequired,
// };

export default LayoutContainer;
