import React, { useContext, useState, useEffect } from "react";
import {
  WrapperModal,
  Buttons,
  Button,
  Text,
  Label,
  Subtitle,
  SettingsItem,
} from "./index.styled";
import { LanguageContext } from "context/locale-context";
import Modal from "react-modal";
import { Link } from "gatsby";
import Cookies from "js-cookie";

const CookiesComponent = () => {
  const { language } = useContext(LanguageContext);
  const [modalIsOpen, setIsOpen] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [useFunctionalCookies, setUseFunctionalCookies] = useState(true);
  const [usePerformanceCookies, setUsePerformanceCookies] = useState(true);

  const customStyles = {
    content: {
      inset: "0",
      overflow: "inherit",
      padding: 0,
      border: 0,
      backgroundColor: "transparent",
    },
    overlay: {
      width: "100%",
      left: 0,
      right: 0,
      bottom: 0,
      height: "100vh",
      zIndex: 999,
      border: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
  };

  Modal.setAppElement("body");

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const isCookiesAccepted = Cookies.get("cookiesAccepted");
    setIsOpen(!isCookiesAccepted);
  }, []);

  const toggleFunctionalCookies = (value) => {
    setUseFunctionalCookies(value);

    handleSaveSettings();
  };

  const togglePerformanceCookies = (value) => {
    setUsePerformanceCookies(value);

    handleSaveSettings();
  };

  const handleSaveSettings = () => {
    Cookies.set(
      "useFunctionalCookies",
      useFunctionalCookies ? "true" : "false",
      { expires: 365 }
    );
    Cookies.set(
      "usePerformanceCookies",
      usePerformanceCookies ? "true" : "false",
      { expires: 365 }
    );
  };
  const acceptCookies = () => {
    console.log("Cookies accepted");
    Cookies.set("cookiesAccepted", "true", { expires: 365 });
    closeModal();
  };

  const rejectCookies = () => {
    console.log("Cookies rejected");
    Cookies.set("cookiesAccepted", "false", { expires: 365 });
    closeModal();
  };

  const configureCookies = () => {
    console.log("Navigate to cookies settings");
    setIsSettingsOpen(!isSettingsOpen);
  };

  return (
    <>
      <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <WrapperModal>
          {isSettingsOpen ? (
            <div>
              <SettingsItem>
                <Subtitle>
                  {language === "en"
                    ? "Functional Cookies"
                    : "Функціональні файли cookie"}
                </Subtitle>
                <Text>
                  {language === "en"
                    ? "Functional cookies allow the website to remember choices you make to give you better functionality and personal features."
                    : "Функціональні файли cookie дозволяють веб-сайту запам'ятовувати вибір, який ви робите щоб надати вам кращу функціональність та персональні можливості."}
                </Text>
                <Label>
                  {language === "en"
                    ? "Use functional cookies"
                    : "Використовувати функціональні файли cookie"}

                  <input
                    type="radio"
                    name="functional-cookies"
                    checked={useFunctionalCookies}
                    onChange={() => toggleFunctionalCookies(true)}
                  />
                  <span className="checkmark"></span>
                </Label>
                <Label>
                  {language === "en"
                    ? "Do not use functional cookies"
                    : "Не використовувати функціональні файли cookie"}
                  <input
                    type="radio"
                    name="functional-cookies"
                    checked={!useFunctionalCookies}
                    onChange={() => toggleFunctionalCookies(false)}
                  />{" "}
                  <span className="checkmark"></span>
                </Label>
              </SettingsItem>
              <SettingsItem>
                <Subtitle>
                  {language === "en"
                    ? "Performance Cookies"
                    : "Cookie-файли продуктивності"}
                </Subtitle>
                <Text>
                  {language === "en"
                    ? "Performance cookies help us to improve the performance of our website."
                    : "Файли cookie продуктивності допомагають нам покращити продуктивність нашого веб-сайту."}
                </Text>
                <Label>
                  {language === "en"
                    ? "Use performance cookies"
                    : "Використовуйте файли cookie продуктивності"}

                  <input
                    type="radio"
                    name="performance-cookies"
                    checked={usePerformanceCookies}
                    onChange={() => togglePerformanceCookies(true)}
                  />
                  <span className="checkmark"></span>
                </Label>
                <Label>
                  {language === "en"
                    ? "Do not use performance cookies"
                    : "Не використовуйте файли cookie продуктивності"}
                  <input
                    type="radio"
                    name="performance-cookies"
                    checked={!usePerformanceCookies}
                    onChange={() => togglePerformanceCookies(false)}
                  />{" "}
                  <span className="checkmark"></span>
                </Label>
              </SettingsItem>
            </div>
          ) : language === "uk" ? (
            <Text>
              Ми використовуємо файли cookies задля забезпечення ефективної
              роботи веб-сайту щодо авторизованих користувачів. Продовжуючи
              відвідування веб-сайту, оберіть ‘Прийняти всі’, щоб надати згоду
              на використання усіх файлів cookies, або ‘Налаштування файлів
              cookies’ задля керування відповідними файлами cookies, або
              виберіть ‘Відхилити всі’, щоб використовувати лише вкрай необхідні
              файли cookies. Ви можете змінити налаштування в будь-який момент,
              якщо натиснете на вкладку веб-сайту «Налаштування файлів cookies».
              Детальне ознайомлення з 
              <Link to="/use-of-cookies/">Політикою</Link>
               використання файлів cookies
            </Text>
          ) : (
            <Text>
              We use cookies to ensure the efficient operation of the website
              for authorized users. When continuing to visit the website, select
              ‘Accept all’ to consent to the use of all cookies, or ‘Settings’
              to manage the relevant cookies, or select ‘Reject all’ to use only
              strictly necessary cookies. You can change your settings at any
              time by clicking on the ‘Cookie Settings’ tab on the website.
              Detailed introduction to the 
              <Link to="/use-of-cookies/">Policy</Link> on the use of cookies
            </Text>
          )}

          <Buttons>
            <Button onClick={configureCookies}>
              {language === "uk" ? "Налаштування" : "Settings"}
            </Button>
            <Button onClick={rejectCookies}>
              {language === "uk" ? "Відхилити всі" : "Reject all"}
            </Button>
            <Button className="accept" onClick={acceptCookies}>
              {language === "uk" ? "Прийняти всі" : "Accept all"}
            </Button>
          </Buttons>
        </WrapperModal>
      </Modal>
    </>
  );
};

export default CookiesComponent;
