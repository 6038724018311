import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Wrapper,
  Logo,
  NavLinkWrapper,
  NavLink,
  Nav,
  ButtonsWrapper,
  SwitchLanguageButton,
  SwitchLanguageButtonInner,
  SearchButton,
  LanguageButton,
  SubNav,
  DesktopWrapper,
  MobileWrapper,
  MobileHeader,
  MobileContent,
  NavMobile,
  MenuIconButton,
  MobileHeaderButtons,
  Icon,
  LogoText,
  SearchWrapper,
  SubNavWrapper,
  SearchInputButton,
  SearchInput,
} from "./index.styled";

import { navigate } from "gatsby";
import { LanguageContext } from "context/locale-context";
import { SearchContext } from "../../context/search-context";
import { Player } from "@lottiefiles/react-lottie-player";

import { useStaticQuery, graphql } from "gatsby";

const LogoLink = ({ onClick, headerTextColor }) => {
  const { language } = useContext(LanguageContext);
  const logoTextSrc =
    language === "en"
      ? headerTextColor === "White"
        ? `/logo-text-eng-white.json`
        : `/logo-text-eng.json`
      : headerTextColor === "White"
      ? `/logo-text-ukr-white.json`
      : `/logo-text-ukr.json`;
  return (
    <Logo $langauage={language} to="/">
      <Icon onClick={onClick}>
        <Player
          autoplay={true}
          loop
          className="player"
          src={
            language === "en" ? `/logo-icon-eng.json` : `/logo-icon-ukr.json`
          }
          style={{ height: "6.1vh" }}
        ></Player>
      </Icon>
      <LogoText onClick={onClick}>
        <Player
          keepLastFrame={true}
          autoplay={true}
          className="player"
          src={logoTextSrc}
          style={{ height: "6.1vh" }}
        ></Player>
      </LogoText>
    </Logo>
  );
};

const SwitchLangButton = ({
  isOpenMenu,
  setIsOpenMenu,
  headerTextColor,
  isHeaderVisible,
}) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const locales = language === "en" ? ["en", "uk"] : ["uk", "en"];

  return (
    <SwitchLanguageButton
      onClick={() => setIsOpenMenu(!isOpenMenu)}
      className={isOpenMenu && "active"}
      $headerTextColor={headerTextColor}
      $isHeaderVisible={isHeaderVisible}
    >
      <SwitchLanguageButtonInner>
        {locales.map((item, i) => {
          return (
            <LanguageButton
              $isHeaderVisible={isHeaderVisible}
              $headerTextColor={headerTextColor}
              key={`language-button-${i}`}
              onClick={() => {
                setLanguage(item);
              }}
            >
              {item === "uk" ? "ua".toUpperCase() : item.toUpperCase()}
            </LanguageButton>
          );
        })}
      </SwitchLanguageButtonInner>
    </SwitchLanguageButton>
  );
};

const SearchComponent = ({
  searchRef,
  handleInputChange,
  handleKeyPress,
  handleSearch,
  headerTextColor,
  language,
}) => {
  return (
    <SearchWrapper ref={searchRef} $headerTextColor={headerTextColor}>
      <SearchInput
        type="text"
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
      />
      <SearchInputButton
        onClick={handleSearch}
        headerTextColor={headerTextColor}
      >
        {language === "en" ? "Search" : "Пошук"}
      </SearchInputButton>
    </SearchWrapper>
  );
};

const Header = ({ location, headerTextColor }) => {
  const { language } = useContext(LanguageContext);
  const { onSearch } = useContext(SearchContext);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showSearchMobile, setShowSearchMobile] = useState(false);
  const searchRef = useRef();

  const { allStrapiHeaderMenu } = useStaticQuery(graphql`
    query {
      allStrapiHeaderMenu {
        nodes {
          locale
          HeaderMenuItems {
            Title
            page {
              Slug
            }
            ExternalUrl
            SubLinks {
              ExternalUrl
              Title
              page {
                Slug
              }
            }
          }
        }
      }
    }
  `);

  const headerMenu = allStrapiHeaderMenu.nodes.find(
    (node) => node.locale === language
  );
  const headerLinks = headerMenu ? headerMenu.HeaderMenuItems : [];

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsHeaderVisible(
        currentScrollTop <= 0 || lastScrollTop > currentScrollTop
      );
      setLastScrollTop(currentScrollTop);
      // setShowSearch(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isOpenMobileMenu) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  }, [isOpenMobileMenu]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleSearch = () => {
    // trackCustomEvent({
    //   category: "Search",
    //   action: "Click",
    //   label: searchValue,
    //   value: 44,
    // });
    onSearch(searchValue);
    if (location.pathname !== "/search") {
      navigate("/search");
    }
    setShowSearch(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearchMobile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Wrapper
      className={isHeaderVisible && "visible"}
      style={{
        backgroundColor:
          lastScrollTop > 100
            ? headerTextColor === "White"
              ? "#000"
              : "#EDEAE7"
            : "",
      }}
    >
      <DesktopWrapper>
        <LogoLink headerTextColor={headerTextColor} />
        <Nav>
          {headerLinks.map((item, i) => {
            const isActive = `/${item?.page?.Slug}/` === location.pathname;
            return (
              <NavLinkWrapper
                key={`header-link-item-${i}`}
                $headerTextColor={headerTextColor}
                $isHeaderVisible={lastScrollTop > 100}
              >
                <NavLink
                  className={isActive && "active"}
                  data={item}
                  $arrow={item.SubLinks.length > 0 && true}
                  $headerTextColor={headerTextColor}
                  $isHeaderVisible={lastScrollTop > 100}
                />
                <SubNavWrapper>
                  <SubNav
                    $headerTextColor={headerTextColor}
                    $display={lastScrollTop > 100}
                  >
                    {item?.SubLinks?.map((subItem, j) => {
                      return (
                        <NavLink
                          key={`header-link-subitem-${j}`}
                          data={subItem}
                        />
                      );
                    })}
                  </SubNav>
                </SubNavWrapper>
              </NavLinkWrapper>
            );
          })}
        </Nav>
        <ButtonsWrapper>
          {showSearch && (
            <SearchComponent
              handleInputChange={handleInputChange}
              handleKeyPress={handleKeyPress}
              handleSearch={handleSearch}
              headerTextColor={headerTextColor}
              language={language}
            />
          )}

          <SearchButton
            $headerTextColor={headerTextColor}
            $isHeaderVisible={lastScrollTop > 100}
            onClick={() => setShowSearch(!showSearch)}
          />
          <SwitchLangButton
            isOpenMenu={isOpenMenu}
            setIsOpenMenu={setIsOpenMenu}
            headerTextColor={headerTextColor}
            isHeaderVisible={lastScrollTop > 100}
          />
        </ButtonsWrapper>
      </DesktopWrapper>
      <MobileWrapper $show={isOpenMobileMenu} $openSearch={showSearch}>
        <MobileHeader>
          <LogoLink
            headerTextColor={headerTextColor}
            onClick={() => setIsOpenMobileMenu(false)}
          />
          <MobileHeaderButtons>
            {isOpenMobileMenu && (
              <SwitchLangButton
                isOpenMenu={isOpenMenu}
                setIsOpenMenu={setIsOpenMenu}
                headerTextColor={headerTextColor}
              />
            )}
            <SearchButton
              $headerTextColor={headerTextColor}
              $isOpenMobileMenu={isOpenMobileMenu}
              onClick={() => setShowSearchMobile(!showSearchMobile)}
            />
            {showSearchMobile && (
              <SearchComponent
                searchRef={searchRef}
                handleInputChange={handleInputChange}
                handleKeyPress={handleKeyPress}
                handleSearch={handleSearch}
                headerTextColor={headerTextColor}
                language={language}
              />
            )}
            <MenuIconButton
              className={isOpenMobileMenu && "active"}
              $headerTextColor={headerTextColor}
              onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
            />
          </MobileHeaderButtons>
        </MobileHeader>
        <MobileContent $show={isOpenMobileMenu}>
          <NavMobile>
            {headerLinks.map((item, i) => {
              const isActive = `/${item?.page?.Slug}/` === location.pathname;
              return (
                <NavLinkWrapper
                  key={`header-link-item-${i}`}
                  $headerTextColor={headerTextColor}
                  onClick={() => setIsOpenMobileMenu(false)}
                >
                  <NavLink
                    className={isActive && "active"}
                    data={item}
                    $arrow={item.SubLinks.length > 0 && true}
                    $headerTextColor={headerTextColor}
                  />
                  <SubNav>
                    {item?.SubLinks?.map((subItem, j) => {
                      return (
                        <NavLink
                          key={`header-link-subitem-${j}`}
                          data={subItem}
                        />
                      );
                    })}
                  </SubNav>
                </NavLinkWrapper>
              );
            })}
          </NavMobile>
        </MobileContent>
      </MobileWrapper>
    </Wrapper>
  );
};

export default Header;
