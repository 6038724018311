import styled from "styled-components";
import { mediaMax } from "helpers/mediaQueries";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";

export const WrapperModal = styled.div`
  position: absolute;
  bottom: ${calculateResponsiveSize(36)};
  right: ${calculateResponsiveSize(36)};
  padding: ${calculateResponsiveSize(31)};
  width: ${calculateResponsiveSize(520)};
  background-color: #fff;
  border-radius: ${calculateResponsiveSize(5)};
  ${mediaMax("desktopSm")} {
    width: 520px;
    padding: 31px;
  }
  ${mediaMax("tablet")} {
    width: 90%;
    padding: 20px;
    bottom: 20px;
    right: 50%;
    transform: translateX(50%);
    border-radius: 0;
  }
`;

// export const WrapperSettings = styled.div`
//   position: absolute;
//   background-color: #fff;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   padding: ${calculateResponsiveSize(31)};
//   width: ${calculateResponsiveSize(520)};
//   background-color: #fff;
//   border-radius: ${calculateResponsiveSize(5)};
// `;

export const Text = styled.p`
  font-size: ${calculateResponsiveSize(11.7)};
  font-style: normal;
  line-height: 1.6;
  /* text-decoration-line: underline; */
  a {
    color: inherit;
  }
  ${mediaMax("desktopSm")} {
    font-size: 12px;
  }
  ${mediaMax("tablet")} {
    font-size: 14px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${calculateResponsiveSize(27)};
  gap: ${calculateResponsiveSize(5)};
  ${mediaMax("desktopSm")} {
    gap: 5px;
    margin-top: 25px;
  }
  ${mediaMax("tablet")} {
    flex-direction: column-reverse;
  }
`;

export const Button = styled.button`
  padding: ${calculateResponsiveSize(15)} 0;
  flex: 1;
  text-align: center;
  font-size: ${calculateResponsiveSize(10)};
  text-transform: uppercase;
  color: #000;
  border: ${calculateResponsiveSize(1)} solid #000;
  cursor: pointer;
  &.accept {
    background-color: #000;
    color: #fff;
  }
  ${mediaMax("desktopSm")} {
    font-size: 13px;
    padding: 12px 0;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-top: ${calculateResponsiveSize(10)};
  position: relative;
  padding-left: ${calculateResponsiveSize(20)};
  /* margin-bottom: ${calculateResponsiveSize(12)}; */
  cursor: pointer;
  font-size: ${calculateResponsiveSize(10)};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: ${calculateResponsiveSize(12.5)};
    width: ${calculateResponsiveSize(12.5)};
    background-color: #eee;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.75);
  }
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input:checked ~ .checkmark {
    background-color: #000;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    top: ${calculateResponsiveSize(4.5)};
    left: ${calculateResponsiveSize(4.5)};
    width: ${calculateResponsiveSize(4)};
    height: ${calculateResponsiveSize(4)};
    border-radius: 50%;
    background: white;
  }
  ${mediaMax("desktopSm")} {
    font-size: 14px;
    padding-left: 30px;
    margin-top: 20px;
    .checkmark {
      width: 16px;
      height: 16px;
      &:after {
        width: 6px;
        height: 6px;
        top: 4.5px;
        left: 4.5px;
      }
    }
  }
`;

export const Subtitle = styled.h4`
  text-align: center;
  font-size: ${calculateResponsiveSize(14)};
  margin-bottom: ${calculateResponsiveSize(10)};
  font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

export const SettingsItem = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-top: ${calculateResponsiveSize(20)};
    ${mediaMax("desktopSm")} {
      margin-top: 20px;
    }
  }
`;
